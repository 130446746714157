
<section class="new-login-employer-bg">
    <app-header></app-header>
  <div class="mid-content mid-bar">
    <div class="container">
      <div class="content-info">
        <div class="row">
          <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img welcome-sign-up" /></div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-md-12">
                <div class="signup-warp animate__fadeInRightBig animate__animated">
                  <h3>Request Demo</h3>
                  <!-- <h6 class="close-icon"><a href="javascript:void(0);"><img src="assets/images/close-icon.png" /></a></h6> -->
                  <form  [formGroup]="userRegisterForm">
                    <!-- <div class="private-user-body"> -->
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <!-- <label>First Name</label> -->
                            <input class="form-control inputField" type="text" placeholder="Company Name*" formControlName="company_name" />
                          </div>
                          <mat-error *ngIf="companyName.errors?.minlength || companyName.errors?.maxlength">
                            Company Name must be <strong>2-15 characters</strong> long.
                          </mat-error>
                          <mat-error *ngIf="(companyName.touched || isSubmitted) && companyName.hasError('required')">Company Name is
                              <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="companyName.hasError('patternInvalid')">Company Name has <strong>incorrect format</strong>
                          </mat-error>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <!-- <label>Last Name</label> -->
                            <input class="form-control inputField" type="text" placeholder="Company Address*" formControlName="company_address" />
                          </div>
                          <mat-error *ngIf="(companyAddress.touched||isSubmitted) && companyAddress.hasError('required')">Company Address is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="companyAddress.errors?.minlength || companyAddress.errors?.maxlength">
                            Company Address must be <strong>2-50 characters</strong> long.
                          </mat-error>
                          <mat-error *ngIf="companyAddress.hasError('patternInvalid')">Company Address has <strong>incorrect format</strong>
                          </mat-error>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                              <!-- <label>First Name</label> -->
                              <input class="form-control inputField" type="text" placeholder="Contact Name*" formControlName="contact_name" />
                            </div>
                            <mat-error *ngIf="contactName.errors?.minlength || contactName.errors?.maxlength">
                                Contact Name must be <strong>2-15 characters</strong> long.
                            </mat-error>
                            <mat-error *ngIf="(contactName.touched || isSubmitted) && contactName.hasError('required')">Contact Name is
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="contactName.hasError('patternInvalid')">Contact Name has <strong>incorrect format</strong>
                            </mat-error>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <!-- <label>Last Name</label> -->
                              <input class="form-control inputField" type="text" placeholder="Contact Number*" formControlName="contact_number" />
                            </div>
                            <mat-error *ngIf="(contactNumber.touched||isSubmitted) && contactNumber.hasError('required')">Contact Number is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="contactNumber.hasError('patternInvalid')">Contact number has <strong>incorrect format</strong>
                            </mat-error>
                          </div>
                        <!-- <div class="col-sm-12">
                          <div class="form-group">
                            <label>Date of Birth</label>
                            <input class="form-control inputField" type="date" id="birthday" placeholder="Date of Birth" formControlName="birthday" [max]="maxBirthdayDate" (change)="getDate()" />
                            
                          </div>
                            <mat-error *ngIf="isSubmitted && birthday.hasError('required')">Birth day is <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="birthday.hasError('matDatepickerMax')">The date is <strong>not allowed</strong>
                            </mat-error>
                        </div> -->
                       
                        <div class="col-sm-12">
                          <div class="form-group">
                            <!-- <label>Email</label> -->
                            <input class="form-control inputField" type="text" placeholder="Contact Email Address*" formControlName="email"/>
                          </div>
                          <mat-error *ngIf="isSubmitted && email.hasError('required')">Contact Email Address is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="isSubmitted && email.hasError('patternInvalid')">Contact Email Address is <strong>invalid</strong>
                          </mat-error>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group">
                            <!-- <label>LinkedIn URL</label> -->
                            <input class="form-control inputField" type="text" placeholder="Company Website*" formControlName="company_website" />
                          </div>
                          <mat-error *ngIf="(companyWebsite.touched||isSubmitted) && companyWebsite.hasError('required')">Company Website is <strong>required</strong>
                          </mat-error>
                          <!-- <mat-error *ngIf="linkedUrl.errors?.minlength || linkedUrl.errors?.maxlength">
                            Last name must be <strong>2-15 characters</strong> long.
                          </mat-error>
                          <mat-error *ngIf="linkedUrl.hasError('patternInvalid')">Last name has <strong>incorrect format</strong>
                          </mat-error> -->
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                              <!-- <label>LinkedIn URL</label> -->
                              <select class="form-control" formControlName="hire_position">
                                <option selected disabled [value]="''">Number of positions hiring *</option>
                                <option [value]="0">0-3</option>
                                <option [value]="1">4-6</option>
                                <option [value]="2">7-10</option>
                                <option [value]="3">10-20</option>
                                <option [value]="4">20+</option>
                              </select>
                            </div>
                            <mat-error *ngIf="isSubmitted && hirePosition.hasError('required')">Number of positions is <strong>required</strong>
                            </mat-error>
                        </div>
                        <!-- <div class="col-sm-12">
                          <div class="form-group">
                            <label>Gender</label>
                            <ul class="parent_question-genral">
                              <li>
                                <input type="radio" id="male" name="gender" value="male" formControlName="gender">
                                <label for="male">Male</label>
                                <div class="check"></div>
                              </li>
                              <li>
                                <input type="radio" id="female" name="gender" value="female" formControlName="gender">
                                <label for="female">Female</label>
                                <div class="check"></div>
                              </li>
                            </ul>
                          </div>
                        </div> -->
                      
                        
                      </div>
                      <div class="row">
                        <!-- <div class="col-sm-6 col-xs-6">
                        <div class="form-group">
                        <button class="btn btn-block" type="button" (click)="userRegisterForm.reset()">Reset All</button>
                        </div>
                        
                        </div> -->
                        <div class="col-sm-12 col-xs-12">
                        <div class="form-group register-btn">
                        <a class="btn" (click)="register()">Request Demo</a>
                        </div>
                        
                        </div>
                        
                        </div>
                    <!-- </div> -->
                  </form>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  
  