import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-dialog',
  templateUrl: './invitation-dialog.component.html',
  styleUrls: ['./invitation-dialog.component.css']
})
export class InvitationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
