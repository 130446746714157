<div class="step-3-dialog">
  <i class="fas fa-times dialogs-close-btn" mat-dialog-close></i>
  <img class="dialog-img" src="../../../../../assets/images/dialog-1.png">
  <p class="step-3-text-1">Upload PDf</p>
  <label class="step-3-text-2" for="file">
    <input type="file" id="file" name="file" multiple>
    Upload PDf
    <img class="click-img" src="../../../../../assets/images/click.svg">
  </label>
  <p class="step-3-text-3">… or simply drag and drop your PDF file here...</p>
  <div class="step-3-text-4">Your PDF must be less than 150 MB</div>
</div>


