
<div *ngIf="isLoder" class="loader"> <svg width="80" height="80" viewBox="0 0 38 38" stroke="#fff">
  <g fill="none" fill-rule="evenodd">
    <g transform="translate(1 1)" stroke-width="2">
      <circle stroke-opacity=".2" cx="18" cy="18" r="18"/>
      <path d="M36 18c0-9.94-8.06-18-18-18">
        <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"/>
      </path>
    </g>
  </g>
  </svg> </div>
<app-header></app-header>
<div class="mid-content mid-bar">
  <div class="container-fluid"> 
    <!--<div class="sound-icon"><a href="javascript:void(0);"><img src="assets/images/sound-off.png" alt="Sound" title="Sound" /> </a></div>-->
    
    <div class="content-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h1 class="animate__fadeInRight animate__animated job-title"> 
              I can always refine the search if you have an industry or a job title in mind?
              <!-- <span id="findJobText"></span> <i class="hr-line"></i> -->
             </h1>
              <div class="row Search_section">
                <div class="col-md-7 Search-field">
                <div class="search-box-location search-job-section">
                  <form class="example-form" [formGroup]="searchForm"  (ngSubmit)="findJob()">
                    <div class="input-group animate__fadeInLeftBig animate__animated"> 
                 
                      <!--<div class="input-group-btn">
                      <div class="btn-group"> <i class="pin"><img src="assets/images/job-icon.png" /></i> </div>
                    </div>-->
                      
                      <input type="text" class="form-control search-input" placeholder="Search" 
                    formControlName="keyword"
                     (keyup)="onKeyUp($event,'keyword')"
                     [matAutocomplete]="auto">
                     <!-- <i class="fa fa-search"></i> -->
                     <svg xmlns="http://www.w3.org/2000/svg" class="search-svg" width="31.682" height="31.7" viewBox="0 0 31.682 31.7">
                      <path id="Path_1290" data-name="Path 1290" d="M15.725,2.494A13.856,13.856,0,1,0,29.45,16.349a13.922,13.922,0,0,0-4.014-9.791A13.66,13.66,0,0,0,15.725,2.494ZM5.311,16.349A10.48,10.48,0,0,1,15.725,5.8a10.35,10.35,0,0,1,7.358,3.082,10.611,10.611,0,0,1,3.057,7.462,10.415,10.415,0,1,1-20.829,0ZM29.1,27.276a1.655,1.655,0,1,0-2.341,2.341l4.092,4.092A1.656,1.656,0,1,0,33.2,31.368Z" transform="translate(-2 -2.494)" fill="#cbb12f" fill-rule="evenodd"/>
                    </svg>
                    

                     <!-- <img src = "search-icon.svg" alt="search-icon"/> -->
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let job of jobSearchData" [value]="job.jobTitle"> {{job.jobTitle}} </mat-option>
                      </mat-autocomplete>
                      <!-- <div class="input-group-btn">
                        <div class="btn-group search-btn">
                          <i class="fa fa-search"></i>
                          <button type="submit" class="btn" >Search</button>
                        </div>
                      </div> -->
                    </div>
                  </form>
                </div>
                </div>
                <div class="col-md-5 SelectJob">
                  <select class="selectpicker select-job" (change)="changeCity($event.target.value)" autocomplete="off">
                    <option *ngIf="singleJob">All Categories</option>
                    <option *ngFor="let city of singleJob" [value]="city.id">{{city.city}},{{city.state_code}}</option>
                    <option *ngFor="let city of stateData" [value]="city.city">{{city.city}}</option>
                  </select>
                </div>
              </div>
           
            
            <!-- <div class="row">
              <div class="col-sm-4"></div>
              <div class="col-sm-4"><img src="assets/images/sparkle-test.png" class="welcome-img img-responsive"/></div>
              <div class="col-sm-4"></div>
            </div> -->
            <div class="select-your-job animate__fadeInUpBig animate__animated">
              <div class="row head-section">
                <div class="col-sm-6"><strong>Designation:</strong></div>
                <div class="col-sm-2"><strong>Location:</strong></div>
                <div class="col-sm-4"><strong>Posted on:</strong></div>
              </div>
              <div *ngIf="jobs && jobs.length == 0" class="notFound"><i class="fa fa-exclamation" aria-hidden="true"></i>No record found</div>
              <ul>
                <li *ngFor="let job of jobs">
                  <div class="row content-row">
                    <div class="col-sm-6"><h4 class="jobStatus"> {{job.jobTitle}}<span>{{job.employment}}</span> </h4></div>
                    <div class="col-sm-2 small-grid"><h4 class="jobLocation"><strong>Location</strong> {{job.country}}  </h4></div>
                    <div class="col-sm-4 small-grid text-right"><h4 class="postedOn"><strong>Posted on</strong> {{job.dateOpened | date:'longDate'}} <span><a  (click)="router.navigate(['jobs/show-job',job.id])">
                      <svg id="Regular_1.5px_show" data-name="Regular 1.5px/show" xmlns="http://www.w3.org/2000/svg" width="33.334" height="24.025" viewBox="0 0 33.334 24.025">
                      <g id="Group" transform="translate(0 0)">
                        <path id="Combined_Shape" data-name="Combined Shape" d="M16.666,24.025A16.881,16.881,0,0,1,6.012,20.266,23.483,23.483,0,0,1,.539,13.957a3.777,3.777,0,0,1,0-3.89A23.49,23.49,0,0,1,6.012,3.759a16.982,16.982,0,0,1,21.31,0A23.516,23.516,0,0,1,32.8,10.067a3.777,3.777,0,0,1,0,3.89,23.508,23.508,0,0,1-5.473,6.309A16.882,16.882,0,0,1,16.666,24.025Zm0-21.451A14.4,14.4,0,0,0,7.6,5.782a20.839,20.839,0,0,0-4.857,5.61,1.172,1.172,0,0,0,0,1.242A20.839,20.839,0,0,0,7.6,18.243a14.41,14.41,0,0,0,18.127,0,20.815,20.815,0,0,0,4.856-5.61,1.172,1.172,0,0,0,0-1.242,20.815,20.815,0,0,0-4.856-5.61A14.4,14.4,0,0,0,16.666,2.575Z"/>
                        <path id="Combined_Shape-2" data-name="Combined Shape" d="M5.323,10.647a5.324,5.324,0,1,1,5.325-5.324A5.33,5.33,0,0,1,5.323,10.647Zm0-7.985A2.661,2.661,0,1,0,7.985,5.323,2.664,2.664,0,0,0,5.323,2.662Z" transform="translate(11.596 6.655)"/>
                      </g>
                    </svg>
                  </a> 
                    <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23.891" height="24" viewBox="0 0 23.891 24">
                      <path id="Path_1288" data-name="Path 1288" d="M16.533,3.843a5.356,5.356,0,0,0-2.281-1.284,2.147,2.147,0,0,0-1.873.281,3.317,3.317,0,0,0-.948.991,1.754,1.754,0,0,0-.263.916l-.043,3.476L7.58,12.7a4.126,4.126,0,0,0-.819,1.8l-.018.1v.1l-.052,4.2h0V23.72a2.625,2.625,0,0,0,2.593,2.625l8.88.109c3.723.046,5.522-2.186,5.961-3.614a40.217,40.217,0,0,0,1.723-7.457A3.982,3.982,0,0,0,21.9,10.845l-4.136-.051-.017-.021a.4.4,0,0,1-.035-.055.314.314,0,0,1-.021-.048v0A27.22,27.22,0,0,0,17.907,7.4,4.714,4.714,0,0,0,16.533,3.843Zm-3.16,4.537.043-3.464c.018-.023.038-.047.058-.069a.789.789,0,0,1,.142-.127h.005a3.124,3.124,0,0,1,1.337.731,2.475,2.475,0,0,1,.7,1.92,24.821,24.821,0,0,1-.2,3.021,2.4,2.4,0,0,0,.5,1.74,2.18,2.18,0,0,0,1.673.912l4.247.052a1.732,1.732,0,0,1,1.745,1.958A37.927,37.927,0,0,1,22,22.088l-.015.039-.012.04c-.147.506-1.088,2.07-3.786,2.036l-8.88-.109a.375.375,0,0,1-.37-.375V18.912l.05-4.08a1.875,1.875,0,0,1,.354-.733l3.623-4.58A1.874,1.874,0,0,0,13.373,8.379ZM3.347,14A1.347,1.347,0,0,0,2,15.351v8.434a1.347,1.347,0,0,0,2.694,0V15.351A1.347,1.347,0,0,0,3.347,14Z" transform="translate(-2 -2.455)" fill-rule="evenodd"/>
                    </svg>
                    </a></span></h4></div>
                  </div>
                  
                  
                  
                </li>
              </ul>
            </div>
          </div>
          
          <!-- <div class="col-sm-1"> </div> -->
        </div>

      </div>
      
    </div>
  </div>
</div>
<!-- <div class="global-icon animate__fadeInBottomRight animate__animated"><img src="assets/images/global.png" alt="Global" title="Global" class="img-responsive" /> </div> -->
