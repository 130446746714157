<!-- <div class="paviwelcome mid-bar">
<div *ngIf="!showWelcomeText"class="logo ">
    <figure><a (click) = showAboutPage(); href="javascript:void(0);"><img src="assets/images/logo.png" alt="Pavi" title="Pavi" class="img-responsive" /><h2>Pavi</h2></a></figure>
  </div>

  <div *ngIf="showWelcomeText" class="mid-content mid-bar" style="margin-top:10%">
    <div class="container">
    <div class="content-info typewriter" >
    <h1><img src="assets/images/sparkle-test.png" class="welcome-img" />Welcome to PAVI</h1>
    
    </div>
    </div>
    
    
    </div>
  </div> -->

<app-header></app-header>

  <div class="paviwelcome mid-bar">
    <div *ngIf="!showWelcomeText"class="logo ">
        <figure><a class="logoAnchor" routerLink="/welcome"  routerLinkActive="active"><img src="assets/images/logo.png" alt="Pavi" title="click here to get started" class="img-responsive" />

          <svg viewBox="0 0 800 600">
            <symbol id="s-text">
              <text text-anchor="middle"
                    x="50%"
                    y="35%"
                    class="text--line"
                    >
                PAVI
              </text>    
            </symbol>
            
            <g class="g-ants">
              <use xlink:href="#s-text"
                class="text-copy"></use>     
              <use xlink:href="#s-text"
                class="text-copy"></use>     
              <use xlink:href="#s-text"
                class="text-copy"></use>     
              <use xlink:href="#s-text"
                class="text-copy"></use>     
              <use xlink:href="#s-text"
                class="text-copy"></use>     
            </g>
            
            
          </svg>

        </a></figure>
        <h3 class="color-yellow">Visual Intelligence</h3>
      </div>
    
      <div *ngIf="showWelcomeText" class="mid-content mid-bar" style="margin-top:10%">
        <div class="container">
        <div class="content-info typewriter" >
        <h1><img src="assets/images/sparkle-test.png" class="welcome-img" />Welcome to PAVI</h1>
        
        </div>
        </div>
        
        
        </div>
      </div>