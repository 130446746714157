
<div *ngIf="loginid==2" class="new-login-employer-bg">
  <app-header></app-header>
  <div class="mid-content mid-bar">
    <div class="container">
      <div class="content-info">
        <div class="row">
          <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
          <div class="col-sm-10">
            <!-- <h1 class="animate__fadeInRight animate__animated"> Login as an Employer <i class="hr-line"></i> </h1> -->
            <h1 id="employertext">  </h1>
            <div class="new-login-col">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <form class="login-form" [formGroup]="loginForm">
                      <div class="col-md-5 col-sm-5 col-lg-5 col-xs-6">
                        <div class="input-group">
                          <div class="input-group-btn">
                            <div class="btn-group"> <i class="login-icon"><img src="assets/images/new-login-user.png" /></i> </div>
                          </div>
                          <input type="text" class="form-control" placeholder="Username" formControlName="email"  />
                        </div>
                        <mat-error *ngIf="isSubmitted && email.hasError('required')">E-mail is <strong>required</strong></mat-error>
                      </div>
                      <div class="col-md-5 col-sm-5 col-lg-5 col-xs-6">
                        <div class="input-group">
                          <div class="input-group-btn">
                            <div class="btn-group"> <i class="login-icon"><img src="assets/images/new-login-key.png" /></i> </div>
                          </div>
                          <input type="Password" class="form-control" placeholder="Password" formControlName="password" />
                          
                        </div>
                        <mat-error *ngIf="isSubmitted && pass.hasError('required')">Password is <strong>required</strong>
                        </mat-error>
                      </div>
                      <div class="col-md-2 col-sm-2 col-lg-2 col-xs-12">
                        <button class="btn" type="button" (click)="login()">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="social-icon">
                <h4>Or sign up using</h4>
                <ul>
                  <li><a href="javascript:void(0);"><img src="assets/images/facebook.png" /></a></li>
                  <li><a href="javascript:void(0);"><img src="assets/images/google-plus.png" /> </a></li>
                  <li><a href="javascript:void(0);"><img src="assets/images/mac-icon.png" /></a></li>
                </ul>
                <!-- <h5>Dont have an account yet? 
                  <a (click)="router.navigate(['auth/company-registration'])"> Sign up </a>
                </h5> -->
                <h5>Forgot Password?<a (click)="router.navigate(['forgot-password'])"> Reset Here  </a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loginid==1" class="new-login-bg">
  <app-header></app-header>
  <div class="mid-content mid-bar">
    <div class="container">
      <div class="content-info">
        <div class="row">
          <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
          <div class="col-sm-10">
            <!-- <h1 class="animate__fadeInRight animate__animated"> Login as a Job Seeker <i class="hr-line"></i> </h1> -->
            <h1 id="employeetext"> </h1>
            <div class="new-login-col">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <form class="login-form" [formGroup]="loginForm">
                      <div class="col-md-5 col-sm-5 col-lg-5 col-xs-6">
                        <div class="input-group">
                          <div class="input-group-btn">
                            <div class="btn-group"> <i class="login-icon"><img src="assets/images/new-login-user.png" /></i> </div>
                          </div>
                          <input type="text" class="form-control" placeholder="Username" formControlName="email" />  
                        </div>
                        <mat-error *ngIf="isSubmitted && email.hasError('required')">E-mail is <strong>required</strong></mat-error>
                      </div>
                      <div class="col-md-5 col-sm-5 col-lg-5 col-xs-6">
                        <div class="input-group">
                          <div class="input-group-btn">
                            <div class="btn-group"> <i class="login-icon"><img src="assets/images/new-login-key.png" /></i> </div>
                          </div>
                          <input type="Password" class="form-control" placeholder="Password" formControlName="password" />
                        </div>
                        <mat-error *ngIf="isSubmitted && pass.hasError('required')">Password is <strong>required</strong>
                        </mat-error>
                      </div>
                      <div class="col-md-2 col-sm-2 col-lg-2 col-xs-12">
                        <button class="btn" type="button" (click)="login()">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="social-icon">
                <h4>Or sign up using</h4>
                <ul>
                  <li><a href="javascript:void(0);"><img src="assets/images/facebook.png" /></a></li>
                  <li><a href="javascript:void(0);"><img src="assets/images/google-plus.png" /> </a></li>
                  <li><a href="javascript:void(0);"><img src="assets/images/mac-icon.png" /></a></li>
                </ul>
                <h5>Dont have an account yet? <a (click)="router.navigate(['auth/user-registration'],{ queryParams: { job: id } })"> Sign up </a></h5>

                <h5>Forgot Password?<a (click)="router.navigate(['forgot-password'])"> Reset Here  </a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="employeetext"></div>
 <div id="employertext"></div>