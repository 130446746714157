
<section class="new-login-employer-bg">
    <app-header></app-header>
  <div class="mid-content mid-bar">
    <div class="container">
      <div class="content-info">
        <div class="row">
          <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img welcome-sign-up" /></div>
          <div class="col-sm-10">
            <div class="row">
              <div class="col-md-12">
                <div class="signup-warp animate__fadeInRightBig animate__animated">
                  <h3>Forgot Password</h3>
                  <!-- <h6 class="close-icon"><a href="javascript:void(0);"><img src="assets/images/close-icon.png" /></a></h6> -->
                  <form  [formGroup]="forgotPasswordForm">
                    <!-- <div class="private-user-body"> -->
                      <div class="row">
                       
                        <div class="col-sm-12">
                          <div class="form-group">
                            <!-- <label>Email</label> -->
                            <input class="form-control inputField" type="text" placeholder="Email Address*" formControlName="email"/>
                          </div>
                          <mat-error *ngIf="isSubmitted && email.hasError('required')">Email Address is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="isSubmitted && email.hasError('patternInvalid')">Email Address is <strong>invalid</strong>
                          </mat-error>
                        </div>
                      
                      </div>
                      <div class="row">
                        <!-- <div class="col-sm-6 col-xs-6">
                        <div class="form-group">
                        <button class="btn btn-block" type="button" (click)="userRegisterForm.reset()">Reset All</button>
                        </div>
                        
                        </div> -->
                        <div class="col-sm-12 col-xs-12">
                        <div class="form-group register-btn">
                        <a class="btn" (click)="checkEmail()">Send Mail</a>
                        </div>
                        
                        </div>
                        
                        </div>
                    <!-- </div> -->
                  </form>
                </div>
              </div>
            </div>          
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  
  