<app-header></app-header>
  <div class="mid-content">
    <div class="container">
      <div class="sound-icon"><a href="javascript:void(0);"><img src="assets/images/sound-off.png" alt="Sound" title="Sound" /> </a></div>
      <div class="content-info">
        <div class="row">
          <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
          <div class="col-sm-10">
            <h1> Can I help you find a New Employee? <i class="hr-line"></i></h1>
            <ul class="parent_question">
              <li>
                <input type="radio" id="yes" name="radio-btn"  />
                <label for="yes" (click)="openEmployeeSignUp()">Yes</label>
                <div class="check"></div>
              </li>
              <li>
                <input type="radio" id="no" name="radio-btn" checked=""/>
                <label for="no">No</label>
                <div class="check"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
