<div class="step-3-dialog">
  <i class="fas fa-times dialogs-close-btn" mat-dialog-close></i>
  <img class="dialog-img" src="../../../../../assets/images/work-dialog-img.png">
  <p class="step-3-text-5">Are You Sure That You Want To Send The Invitation?</p>
  <div class="work-buttons-dialog">
    <a class="work-add-edit-2 border-blue">No</a>
    <a class="work-add-edit-1" mat-dialog-close>Yes</a>
  </div>
</div>


