<form [formGroup]="$any(educationForm)">
  <div class="save-work-experience">
    <i class="fas fa-times dialogs-close-btn" mat-dialog-close></i>
    <!--        <owl-carousel-o [options]="owlOptions" #carousel (initialized)="initialized($event)" >-->
    <ng-container *ngFor="let c of educationItems;let ind = index">
      <!--                <ng-template carouselSlide [id]="ind+1">-->
      <ng-container [formGroupName]="ind.toString()">
        <div class="work-header">
          <img src="assets/images/decor-2.svg">
          <p class="work-text-1">Education</p>
        </div>
        <div class="add-programming-items">
          <div class="work-experience-input">
            <label>University / College</label>
            <input placeholder="University / College" formControlName="institution">
          </div>
          <mat-error *ngIf="isSubmitted && c.get('institution').hasError('required')">Institution is
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="c.get('institution').hasError('patternInvalid')">Institution format is
            <strong>invalid</strong>
          </mat-error>
          <div class="work-experience-input">
            <label>Degree</label>
            <input placeholder="Degree" formControlName="degree">
          </div>
          <mat-error *ngIf="isSubmitted && c.get('degree').hasError('required')">Degree is
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="c.get('degree').hasError('patternInvalid')">Degree format is
            <strong>invalid</strong>
          </mat-error>
          <div class="work-experience-input">
            <label>Speciality</label>
            <input placeholder="Speciality" formControlName="speciality">
          </div>
          <mat-error *ngIf="isSubmitted && c.get('speciality').hasError('required')">Speciality is
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="c.get('speciality').hasError('patternInvalid')">Speciality format is
            <strong>invalid</strong>
          </mat-error>
          <div class="work-experience-input">
            <label>Year Of Study</label>
            <div class="work-date-input">
              <div class="year-input">
                <input placeholder="Start year" formControlName="start_year">
              </div>
              <mat-error *ngIf="isSubmitted && c.get('start_year').hasError('required')">Start year is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="c.get('start_year').hasError('patternInvalid') || c.get('start_year').hasError('yearInvalid') ">
                Start year format is
                <strong>invalid</strong>
              </mat-error>
              <mat-error *ngIf="c.get('start_year').hasError('startDateGreater')">
                Start date is greater than end date
              </mat-error>
              <div class="year-input">
                <input placeholder="End year" formControlName="end_year">
              </div>
              <mat-error
                *ngIf="isSubmitted && c.get('end_year').hasError('required') ">End year is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="c.get('end_year').hasError('patternInvalid')|| c.get('end_year').hasError('yearInvalid')">End
                year format is
                <strong>invalid</strong>
              </mat-error>
            </div>
          </div>
        </div>
      </ng-container>
      <!--                </ng-template>-->
    </ng-container>
    <!--        </owl-carousel-o>-->
  </div>
</form>


<!--<div class="add-programming-skills" (click)="addOneMoreFieldsGroup()">-->
<!--    <div class="add-plus"><img src="../../../assets/images/plus.svg"></div>-->
<!--    <div class="add-text">Add One More <span>University.</span></div>-->
<!--</div>-->


<div class="work-buttons">
  <a class="work-add-edit-2" mat-dialog-close>Cancel</a>
  <a class="work-add-edit-1" (click)="saveEducation()">Save</a>
</div>

