import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-social-links-dialog',
  templateUrl: './add-social-links-dialog.component.html',
  styleUrls: ['./add-social-links-dialog.component.css']
})
export class AddSocialLinksDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
