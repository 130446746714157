<app-header></app-header>
  <div class="mid-content mid-bar">
    <div class="container">
      <div class="content-info">
        <div class="row">
          <div class="col-sm-3 col-md-2">
            <!-- <img src="assets/images/sparkle-test.png" class="welcome-img rotate" /> -->
            <!-- <div class="bb welcome-img"></div> -->
            <div class='frame'>
              <div class='front'></div><div class='front'></div><div class='front'></div><div class='front'></div>
              
              <div class='back'></div><div class='back'></div><div class='back'></div><div class='back'></div>
            </div>
          </div>
          <div class="col-sm-9 col-md-10">
            <div class="welcome-info">
              <h1>Welcome to PAVI <i class="hr-line"></i></h1> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sound-icon"><a href="javascript:void(0);"><img src="assets/images/sound.png" alt="Sound" title="Sound" /> </a></div>