<div class="step-3-dialog">
  <i class="fas fa-times dialogs-close-btn" mat-dialog-close></i>
  <img class="dialog-img" src="../../../../../assets/images/dialog-2.png">
  <p class="step-3-text-5">Add Links of Social Medias</p>
  <div class="step-3-social">
    <div class="social-step-3">
      <img src="../../../../../assets/images/fb.png">
    </div>
    <div class="social-step-3">
      <img src="../../../../../assets/images/tw.png">
    </div>
    <div class="social-step-3">
      <img src="../../../../../assets/images/pr.png">
    </div>
    <div class="social-step-3">
      <img src="../../../../../assets/images/be.png">
    </div>
    <div class="social-step-3">
      <img src="../../../../../assets/images/ww.png">
    </div>
  </div>
  <div class="step-3-text-2">Save</div>
</div>
