
<div class="right-header">
    <div class="row">
    
      <div class="col-sm-5 col-md-7 col-xs-9 pull-right">
        <ul>
          <li><img class="dashboard-logo" [src]="profileImage"></li>
          <li><a >{{companyData?.name}} &nbsp;&nbsp;|</a></li>
          <li><a (click)="auth.logOut()">Logout</a></li>
        </ul>
      </div>
      <div class="col-sm-6 col-md-5 col-xs-12">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search" (keyup)="getSuggestion($event.target.value)" />
          <div class="input-group-btn">
            <div class="btn-group">
              <button type="button" class="btn"><i class="fa fa-search"></i></button>
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
  </div>