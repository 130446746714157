<!-- <div class="mid-warp"> -->
<app-header></app-header>
<!-- <div class="mid-col-sec"> -->

<div class="mid-content mid-bar">
 <div class="container">
    <div class="content-info">
      <div *ngIf="jobExp" class="row">
        <div class="col-sm-3 col-md-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
        <div class="col-sm-9 col-md-10">
          <div class="welcome-info">
            <h1>Enter Platform as..... <i class="hr-line"></i></h1>
          </div>
          <ul class="parent_question animate__fadeInLeftBig animate__animated">
            <li>
              <input type="radio" id="no" name="radio-btn" checked=""/>
              <!-- <label for="no" (click)="newEmployee()">Employer</label> -->
              <label for="no" (click)="router.navigate(['auth/login', 2])">Employer</label>
              <div class="check"></div>
            </li>
            <li>
              <input type="radio" id="yes" name="radio-btn"  />
              <!-- <label for="yes" (click)="helpJob()">Interviewer</label> -->
              <label for="yes" (click)="routeYes('jobs/search-job')">Applicant</label>
              <div class="check"></div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="helpFlag" class="row">
        <div class="col-sm-3 col-md-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
        <div class="col-sm-9 col-md-10">
          <!-- <div class="css-typing">
            <h1 >Can I help you explore new opportunities? <i class="hr-line"></i></h1>
          </div> -->
            <h1 id="seekertext"></h1>
          <ul class="parent_question animate__fadeInLeftBig animate__animated">
            <li>
              <input type="radio" id="yes" name="radio-btn" checked="" />
              <label for="yes" (click)="routeYes('jobs/search-job')">Yes</label>
              <div class="check"></div>
            </li>
            <li>
              <input type="radio" id="no" name="radio-btn" />
              <label for="no" (click)="newEmployee()">No</label>
              <div class="check"></div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="findNewEmployee" class="row">
        <div class="col-sm-3 col-md-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
        <div class="col-sm-9 col-md-10">
            <!-- <h1 >Can I help you find a New Employee? <i class="hr-line"></i></h1> -->
            <h1 id="employertext"></h1>
          <ul class="parent_question animate__fadeInLeftBig animate__animated">
            <li>
              <input type="radio" id="yes" name="radio-btn" checked="" />
              <!-- <label for="yes" (click)="routeYes('auth/company-registration')">Yes</label> -->
              <label for="yes" (click)="router.navigate(['auth/login', 2])">Yes</label>
              <div class="check"></div>
            </li>
            <li>
              <input type="radio" id="no" name="radio-btn" />
              <label for="no" (click)="router.navigate(['/'])" >No</label>
              <div class="check"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
 </div>
 </div>
 <div class="sound-icon"><a href="javascript:void(0);"><img src="assets/images/sound.png" alt="Sound" title="Sound" /> </a></div>

 <div id="seekertext"></div>
 <!-- <div id="employertext"></div> -->
 