<div class="col-xs-3 col-sm-1  hidden-lg">
  <div class="nav-tiker"><a (click)="clickEvent()"><img src="assets/images/nav.png" class="nav-tikerimg" /> </a></div>
</div>

<div [ngClass]="status ? 'nav-show' : ''">
<div class="left-bar">
    <div class="nav-tiker-close hidden-md hidden-lg"><a (click)="clickEvent()"><img src="assets/images/nav-close.png" class="nav-tikerimg-close" /> </a></div>
    <figure><img src="assets/images/logowithpavi.png" /></figure>
    <ul>
      <li><a  routerLink="/users/company-interview" routerLinkActive="active">Interview</a></li>
      <li><a routerLink="/users/profile-page" routerLinkActive="active">Profile</a></li>
      <li><a  routerLink="/users/dashboard" routerLinkActive="active" >Dashboard</a></li>
    </ul>
  </div>
</div>