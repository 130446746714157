<aside>
  <div>
    <ul>
      <li>
        <a class="active">
          <img src="../../../../assets/images/aside-1.png">
          <span>Dashboard</span>
        </a>
      </li>
      <li>
        <a>
          <img src="../../../../assets/images/aside-1.png">
          <span>User</span>
        </a>
      </li>
      <li>
        <a>
          <img src="../../../../assets/images/aside-1.png">
          <span>Jobs</span>
        </a>
      </li>

      <li>
        <a>
          <img src="../../../../assets/images/aside-3.png">
          <span>Interview Questions</span>
        </a>
      </li>
      <li>
        <a>
          <img src="../../../../assets/images/aside-4.png">
          <span>My Profile</span>
        </a>
      </li>
    </ul>
  </div>
  <ul>
    <li>
      <a>
        <img src="../../../../assets/images/logout.png">
        <span>Log Out</span>
      </a>
    </li>
  </ul>
</aside>
