 <!-- <header >
    <div class="logo-inner">
      <figure><a routerLink="/" routerLinkActive="active"><img src="assets/images/inner-logo.png" alt="Pavi" title="Pavi" class="img-responsive" /></a></figure>
    </div>

    <div class="nav-menu"> <a (click)="clickEvent()"><img src="assets/images/nav.png" alt="Nav" title="Nav" /></a> </div>
    <div [ngClass]="status ? 'nav-show' : ''">
    <div class="navicon">
      <div class="nav-header-in">
        <div class="logo-inner">
          <figure><a href="javascript:void(0);"><img src="assets/images/inner-logo.png" alt="Pavi" title="Pavi" class="img-responsive" /></a></figure>
        </div>
        <span class="close-menu" (click)="clickEvent()"> <img src="assets/images/nav-close.png" /> </span> </div>
      <div class="navicon-inner">
        <div class="container">
          <div class="row">
            <div class="col-sm-2"><img src="assets/images/sparkle-test.png" class="welcome-img" /></div>
            <div class="col-sm-10">
              <ul> -->
                <!-- <li *ngIf="!auth.loggedIn()" class="active"><a href="/auth/login" class="login" >Login</a></li> -->
                 <!-- <li *ngIf="!auth.loggedIn()" class="active"><a (click)="goToLogin()" class="login" >Login</a></li>
                <li *ngIf="auth.loggedIn()" class="active" ><a (click)="auth.logOut()" class="login" >Logout</a></li>
                <li><a class="about" href="/users/about">About</a></li>
                <li><a class="contact" href="javascript:void(0);">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </header> -->


  <header class="new-head">
    <div class="logo-inner">
      <figure><a routerLink="/" routerLinkActive="active"><img src="assets/images/inner-logo.png" alt="Pavi" title="Pavi" class="img-responsive" /></a></figure>
    </div>
    <div class="nav-tiker hidden-sm hidden-md hidden-lg"><a (click)="clickEvent()"><img src="assets/images/nav.png" class="nav-tikerimg" /> </a></div>
    
    <div [ngClass]="status ? 'nav-show' : ''">
      <div class="new-head-mobile">
        <div class="nav-tiker-close hidden-sm hidden-md hidden-lg"><a (click)="clickEvent()"><img src="assets/images/nav-close.png" class="nav-tikerimg-close" /> </a></div>
        <ul>
        <li><a routerLink="/users/work" routerLinkActive="active">How iT Works</a></li>
        <li><a routerLink="/users/partner" routerLinkActive="active">Partners</a></li>
        <li><a routerLink="/users/about" routerLinkActive="active">About</a></li>
        <li><a routerLink="/request-demo" routerLinkActive="active">Request demo</a></li>
        <li *ngIf="!auth.loggedIn()"><a (click)="goToLogin()" class="login-sec" >Login</a></li>
        <li *ngIf="auth.loggedIn()"><a (click)="auth.logOut()" class="login-sec" >Logout</a></li>
        </ul>
      </div>
    </div>
    
    </header>