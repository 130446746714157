<form [formGroup]="$any(certificationForm)">

  <ng-container *ngFor="let c of certificationItems;let ind = index">
    <ng-container [formGroupName]="ind.toString()">
      <div class="save-work-experience">
        <i class="fas fa-times dialogs-close-btn" mat-dialog-close></i>
        <div class="work-header">
          <img src="assets/images/decor-3.svg">
          <p class="work-text-1">Certification</p>
        </div>
        <div class="add-programming-items">
          <div class="work-experience-input">
            <label>Name Certification</label>
            <input placeholder="Name Certification" formControlName="name">
          </div>
          <mat-error *ngIf="isSubmitted && c.get('name').hasError('required')">Certification name is
            <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="c.get('name').hasError('patternInvalid')">Certification name format is
            <strong>invalid</strong>
          </mat-error>
          <div class="work-experience-input">
            <label>Description</label>
            <input placeholder="Description" formControlName="description">
            <mat-error *ngIf="isSubmitted && c.get('description').hasError('required')">Certification description is
              <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="c.get('description').hasError('patternInvalid')">Certification description format is
              <strong>invalid</strong>
            </mat-error>
          </div>
          <div class="work-experience-input">
            <label>Issued By</label>
            <input placeholder="Organization" formControlName="issued_by">
            <mat-error *ngIf="isSubmitted && c.get('issued_by').hasError('required')">Organization name is
              <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="c.get('issued_by').hasError('patternInvalid')">Organization name format is
              <strong>invalid</strong>
            </mat-error>
          </div>
          <div class="work-experience-input">
            <label>Year Of Study</label>
            <div class="work-date-input">
              <div class="year-input">
                <input placeholder="Start year" formControlName="start_year">
              </div>
              <mat-error *ngIf="isSubmitted && c.get('start_year').hasError('required')">Start year is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="c.get('start_year').hasError('patternInvalid') || c.get('start_year').hasError('yearInvalid') ">
                Start year format is
                <strong>invalid</strong>
              </mat-error>
              <mat-error *ngIf="c.get('start_year').hasError('startDateGreater')">
                Start date is greater than end date
              </mat-error>
              <div class="year-input">
                <input placeholder="End year" formControlName="end_year">
              </div>
              <mat-error
                *ngIf="isSubmitted && c.get('end_year').hasError('required') ">End year is
                <strong>required</strong>
              </mat-error>
              <mat-error
                *ngIf="c.get('end_year').hasError('patternInvalid')|| c.get('end_year').hasError('yearInvalid')">End
                year format is
                <strong>invalid</strong>
              </mat-error>
            </div>
          </div>
        </div>
        <!--        <div class="add-programming-skills">-->
        <!--          <div class="add-plus"><img src="../../../assets/images/plus.svg"></div>-->
        <!--          <div class="add-text">Add One More <span>Certification.</span></div>-->
        <!--        </div>-->


        <div class="work-buttons">
          <a class="work-add-edit-2" mat-dialog-close>Cancel</a>
          <a class="work-add-edit-1" (click)="saveCertification()">Save</a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
