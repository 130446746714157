<div class="about-warp" style="margin-top: 10%;">
    <div class="about-inner">
      <div class="container">
        <div class="row">
          <div class="col-sm-2">
            <div class="logo">
              <figure><a ><img src="assets/images/logo.png" alt="Pavi" title="Pavi" class="img-responsive" />
                <h2>Pavi</h2>
                </a></figure>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="parent">
                <div class="row child">
                    <div class="col-sm-12 col-lg-12">
                        <button type="button" class="btn btn-primary googleButton"><i class="fa fa-google" aria-hidden="true"></i>&nbsp;Sign Up With Google</button>
                    </div>
                    
                    <div><hr></div>
                    <div class="col-sm-12 col-lg-12">
                        <form class="inputForm">
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="firstName">First name</label>
                                    <input type="text" class="form-control" id="firstName" value="First Name*" required>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="lastName">Last name</label>
                                    <input type="text" class="form-control" id="lastName" value="Last Name*" required>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="dateOfBirth">Date Of Birth</label>
                                    <input type="date" class="form-control" id="dateOfBirth" value="Mark" required>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="country">Country</label>
                                    <input type="text" class="form-control" id="country" value="Country*" required>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" value="Email*" required>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="gender">Gender</label>
                                    <div style="padding:12px;">
                                        <label class="radio-inline">
                                            <input type="radio" name="optradio" checked>Male
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="optradio">Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6 mb-3">
                                    <label for="password">Password</label>
                                    <input type="text" class="form-control" id="password" value="Password*" required>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="confirmPassword">Confirm Password</label>
                                    <input type="text" class="form-control" id="confirmPassword" value="Confirm Password*" required>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required>
                                    <label class="form-check-label" for="invalidCheck2">
                                    Agree to terms and conditions
                                    </label>
                                </div>
                            </div>
                            <div class=row>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <button class="btn btn-primary resetButton" type="submit">Reset All</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <button class="btn btn-primary submitButton" type="submit">Sign Up</button>
                                </div>
                            </div>                          
                        </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
